export const autoTypeSupervisor = [
  {
    id: 'boardSider',
    title: 'Бортовой',
    name: 'Бортовой',
    val: 'boardSider',
  },
  {
    id: 'dumpTruckBack',
    title: 'Самосвал (задняя разгрузка)',
    name: 'Самосвал (задняя разгрузка)',
    val: 'dumpTruckBack',
  },
  {
    id: 'dumpTruckSide',
    title: 'Самосвал (боковая разгрузка)',
    name: 'Самосвал (боковая разгрузка)',
    val: 'dumpTruckSide',
  },
  {
    id: 'tank',
    title: 'Цистерна',
    name: 'Цистерна',
    val: 'tank',
  },
  // {
  //   id: 'concreteMixer9cbm',
  //   title: 'Автобетоносмеситель 9 м3',
  //   name: 'Автобетоносмеситель 9 м3',
  //   val: 'concreteMixer9cbm',
  // },
  // {
  //   id: 'concreteMixer12cbm',
  //   title: 'Автобетоносмеситель 12 м3',
  //   name: 'Автобетоносмеситель 12 м3',
  //   val: 'concreteMixer12cbm',
  // },
]
export const autoType = [
  {
    id: 'notStated',
    title: 'Не указан',
    name: 'Не указан',
    val: null,
  },
  {
    id: 'boardSider',
    title: 'Бортовой',
    name: 'Бортовой',
    val: 'boardSider',
  },
  {
    id: 'dumpTruckBack',
    title: 'Самосвал (задняя разгрузка)',
    name: 'Самосвал (задняя разгрузка)',
    val: 'dumpTruckBack',
  },
  {
    id: 'dumpTruckSide',
    title: 'Самосвал (боковая разгрузка)',
    name: 'Самосвал (боковая разгрузка)',
    val: 'dumpTruckSide',
  },
  {
    id: 'tank',
    title: 'Цистерна',
    name: 'Цистерна',
    val: 'tank',
  },
  // {
  //   id: 'concreteMixer9cbm',
  //   title: 'Автобетоносмеситель 9 м3',
  //   name: 'Автобетоносмеситель 9 м3',
  //   val: 'concreteMixer9cbm',
  // },
  // {
  //   id: 'concreteMixer12cbm',
  //   title: 'Автобетоносмеситель 12 м3',
  //   name: 'Автобетоносмеситель 12 м3',
  //   val: 'concreteMixer12cbm',
  // },
]
export const autoTypeRequired = [
  {
    id: 'boardSider',
    title: 'Бортовой',
    name: 'Бортовой',
    val: 'boardSider',
  },
  {
    id: 'dumpTruckBack',
    title: 'Самосвал (задняя разгрузка)',
    name: 'Самосвал (задняя разгрузка)',
    val: 'dumpTruckBack',
  },
  {
    id: 'dumpTruckSide',
    title: 'Самосвал (боковая разгрузка)',
    name: 'Самосвал (боковая разгрузка)',
    val: 'dumpTruckSide',
  },
  {
    id: 'tank',
    title: 'Цистерна',
    name: 'Цистерна',
    val: 'tank',
  },
  // {
  //   id: 'concreteMixer9cbm',
  //   title: 'Автобетоносмеситель 9 м3',
  //   name: 'Автобетоносмеситель 9 м3',
  //   val: 'concreteMixer9cbm',
  // },
  // {
  //   id: 'concreteMixer12cbm',
  //   title: 'Автобетоносмеситель 12 м3',
  //   name: 'Автобетоносмеситель 12 м3',
  //   val: 'concreteMixer12cbm',
  // },
]

export const autoAxle = [
  {
    id: 2,
    title: '2 оси',
    name: '2 оси',
    val: '2',
  },
  {
    id: 4,
    title: '4 оси',
    name: '4 оси',
    val: '4',
  },
  {
    id: 5,
    title: '5 осей',
    name: '5 осей',
    val: '5',
  },
  {
    id: 6,
    title: '6 осей',
    name: '6 осей',
    val: '6',
  },
]
