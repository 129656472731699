<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="propsDialog.width"
    :top="propsDialog.top"
    :custom-class="name"
    :class="classOwn"
    :modal-append-to-body="false"
    @closed="onClose"
    @before-close="handleBeforeClose"
  >
    <template #title>
      <slot name="title" />
      <Icon
        v-if="showClose"
        name="IconClose"
        width="30px"
        height="30px"
        class="header__close"
        @click.native="onClose"
      />
    </template>

    <slot />

    <template #footer>
      <slot name="footer" />
    </template>
  </el-dialog>
</template>

<script>
import Icon from '@/UI/icon/Icon'

export default {
  name: 'Dialog',
  components: { Icon },
  props: {
    name: { type: String, default: '' },
    width: { type: String, default: '728px' },
    classOwn: { type: String, default: '' },
    showClose: { type: Boolean, default: true },
  },
  computed: {
    propsDialog() {
      return this.isMobile || this.width > this.windowWidth
        ? { top: '5vh', width: '94%' }
        : { top: '5vh', width: this.width }
    },
    visible: {
      get() {
        return this.getDialog(this.name).visible || false
      },
      set(val) {
        this.setDialog({ name: this.name, visible: val })
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.visible = false
    },
    handleBeforeClose() {
      this.$emit('beforeClose')
    },
  },
}
</script>
