<template>
  <button
    :disabled="isLoading || disabled"
    class="button df ai-c jc-c"
    :style="buttonStyle"
    :class="{ 'button-disabled': isLoading || disabled }"
    @click="$emit('onClick')"
  >
    <div class="button-text" :style="textStyle">
      {{ titleButton }}
    </div>
  </button>
</template>
<script>
export default {
  name: 'IqMobileButton',
  components: {},
  props: {
    title: { type: String, default: ' Показать еще' },
    buttonStyle: { type: String, default: '' },
    textStyle: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  computed: {
    titleButton() {
      return this.isLoading ? 'Загружается...' : this.title
    },
  },
}
</script>

<style lang="sass" scoped>
.button
  background: #f5f7fa
  border: 1px solid #dcdfe6
  border-radius: 6px
  padding: 8px 32px
  margin: 0 auto
.button-text
  font-size: 14px
  color: #303133
  font-weight: normal
.button-disabled
  opacity: 0.5
</style>
