<template>
  <div class="quota__details">
    <div
      v-for="item in sample.child_templates"
      :key="item.id"
      class="quota__details-item"
    >
      <div class="sample__main mod-child">
        {{ getSupplierTitle(item.suppliers_ids[0]) }}
      </div>
      <div class="sample__progressbar">
        <div class="sample__progressbar-info">
          <div class="info__title">Тайм слоты:</div>
          <div class="info__value">
            {{ item.trucks_allowed_count }}
          </div>
        </div>
      </div>
    </div>
    <div class="quota__details-item">
      <div class="quota__main mod-child mod-unused">
        <div>
          <span>Нераспределенные таймслоты:&nbsp;</span>
          <span class="title__unimproved">{{ getUnimprovedQuota }}</span>
        </div>
      </div>
      <div class="quota__progressbar" />
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'SampleShared',
  props: {
    sample: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      suppliersList: GET_SUPPLIERS_ALL,
      isMobile: GET_IS_MOBILE,
    }),
    getUnimprovedQuota() {
      let improvedQuota = this.sample.child_templates.reduce(
        (result, item) => result + item.trucks_allowed_count,
        0,
      )

      return this.sample.trucks_allowed_count - improvedQuota > 0
        ? this.sample.trucks_allowed_count - improvedQuota
        : 0
    },
  },
  methods: {
    getSupplierTitle(id) {
      let supplier = this.suppliersList.find(item => item.id === id)

      return supplier ? `Поставщик: ${supplier.name}` : 'Общая квота'
    },
  },
}
</script>

<style lang="sass" scoped>
.quota__details
  display: flex
  flex-direction: column
  gap: 18px
  margin: 18px 0 18px 27px
  @media (max-width: 1200px)
    margin: 16px 0
    padding: 14px
    background: $color-main-background
    border-radius: 8px
  &-item
    display: flex
    justify-content: space-between
    align-items: center
    @media (max-width: 1200px)
      flex-direction: column

  .mod-unused
    margin-right: 163px

  @media (max-width: 1200px)
    .mod-child
      flex: 0
      width: 100%
      margin-right: 0
      margin-bottom: 10px
      padding: 0
      background: transparent
      border: none
    .quota__progressbar
      flex: 0
      width: 100%
      &-info
        justify-content: space-between
        font-size: 12px
</style>
